<template>
    <el-dialog 
        title="修改会员设置" 
        :visible.sync="dialogVisible" 
        :append-to-body="true"
        width="70%"
        center
        @close="close"
    >
        <div class="body_con" v-loading="eloading">
            <div class="card"
                :class="radio == 1 ? 'active' : ''"
            >
                <el-radio v-model="radio" label="1" style="margin-bottom: 15px;">会员级别</el-radio>
                <div class="mcontent">
                    <div class="row d-flex"
                        v-for="(item,index) in levelList"
                        :key="index"
                    >
                        <div class="name">{{item.levelName}}</div>
                        <div class="money_con d-flex">
                            <div class="val">会费金额</div>
                            <el-input 
                                placeholder="请输入会费金额"
                                v-model="item.money"
                                maxlength="11" 
                                show-word-limit
                                oninput="value=value.replace(/[^0-9.]/g,'')"
                                clearable
                            ></el-input>
                            <div class="val">元，会员有效期</div>
                            <el-input 
                                placeholder="请输入会员有效期天数"
                                v-model="item.days"
                                maxlength="11" 
                                show-word-limit
                                clearable
                                oninput="value=value.replace(/[^\d]/g,'')"
                            ></el-input>
                            <div class="val">天</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card"
                :class="radio == 2 ? 'active' : ''"
            >
                <el-radio v-model="radio" label="2" style="margin-bottom: 15px;">性别</el-radio>
                <div class="mcontent">
                    <div class="row d-flex"
                        v-for="(item,index) in sexList"
                        :key="index"
                    >
                        <div class="name">{{item.levelName}}</div>
                        <div class="money_con d-flex">
                            <div class="val">会费金额</div>
                            <el-input 
                                placeholder="请输入会费金额"
                                v-model="item.money"
                                maxlength="11" 
                                show-word-limit
                                oninput="value=value.replace(/[^0-9.]/g,'')"
                                clearable
                            ></el-input>
                            <div class="val">元，会员有效期</div>
                            <el-input 
                                placeholder="请输入会员有效期天数"
                                v-model="item.days"
                                maxlength="11" 
                                show-word-limit
                                clearable
                                oninput="value=value.replace(/[^\d]/g,'')"
                            ></el-input>
                            <div class="val">天</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    

        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" :loading="load" @click="sure">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import {selectMemberFeeList,updataMemberMoney} from '@/api/organization.js';

export default {
    data() {
        return {
            dialogVisible: false,
            radio: '1',
            levelList: [],
            sexList: [],
            load: false,
            eloading: false,
        };
    },
    methods: {
        init(){
            this.queryPageMoney();
            this.dialogVisible = true; 
        },
        queryPageMoney() {
            this.eloading = true;
            let data = {
                associationId: localStorage.getItem("associationId"),
            };
            selectMemberFeeList(data).then(res=>{
                if(res.code == 200){
                    this.eloading = false;
                    if(res.data.length){
                        res.data.forEach(v=>{
                            v.money = (v.money/100).toFixed(2)
                        })
                        this.levelList = res.data.filter(v => v.type == 0);
                        this.sexList = res.data.filter(v => v.type == 1);

                        let levers = this.levelList.filter(v => v.selectFlag);
                        let sexs = this.sexList.filter(v => v.selectFlag);
                        if(levers.length){
                            this.radio = '1';
                        };
                        if(sexs.length){
                            this.radio = '2';
                        };
                    }
                }else{
                    this.eloading = false;
                }
            }).catch(c=>{
                this.eloading = false;
            })
        },
        close(){
            this.dialogVisible = false;
            this.radio = '1';
            this.levelList = [];
            this.sexList = [];
        },
        sure(){
            let opts = {};
            if(this.radio == 1){
                let m = this.levelList.filter(v=> v.money == '' || v.money == undefined),
                    d = this.levelList.filter(v=> v.days == '' || v.days == undefined);
                if(m.length || d.length){
                    this.$message.error('请完善信息!');
                    return;
                }
            };
            if(this.radio == 2){
                let m = this.sexList.filter(v=> v.money == '' || v.money == undefined),
                    d = this.sexList.filter(v=> v.days == '' || v.days == undefined);
                if(m.length || d.length){
                    this.$message.error('请完善信息!');
                    return;
                }
            };
            if(this.radio == 1){
                opts.memberFeeModelList = this.levelList.map(v=>{
                    return {
                        memberFeeId: v.memberFeeId,
                        levelName: v.levelName,
                        memberLevelId: v.memberLevelId,
                        days: v.days,
                        money: parseInt(v.money * 100)
                    }
                })
            };
            if(this.radio == 2){
                opts.memberFeeModelList = this.sexList.map(v=>{
                    return {
                        memberFeeId: v.memberFeeId,
                        levelName: v.levelName,
                        memberLevelId: v.memberLevelId,
                        days: v.days,
                        money: parseInt(v.money * 100)
                    }
                })
            };
            this.load = true;
            updataMemberMoney(opts).then(res=>{
                if(res.code == 200){
                    this.$message.success(res.message);
                    this.load = false;
                    this.$emit('success');
                    this.close();
                }else{
                    this.load = false;
                    this.$message.error(res.message);
                }
            }).catch(c=>{
                this.load = false;
                this.$message.error(res.message);
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .body_con{
        .card{
            width: 100%;
            border: 2px dashed #CCCCCC;
            border-radius: 10px;
            margin-bottom: 27px;
            padding: 18px 16px;
            &:last-child{
                margin-bottom: 0;
            }

            &.active{
                border-color: #51CDCB;
            }

            .mcontent{

                .row{
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 16px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }

                .name{
                    font-size: 14px;
                    font-weight: 800;
                    color: #333333;
                }

                .money_con{
                    align-items: center;

                    .val{
                        font-size: 14px;
                        font-weight: 400;
                        color: #454A4C;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
    /deep/.el-input{
        margin: 0 10px;
    }
</style>