<!-- <template>
  <div class="corporateInformation-view">
    <div class="members_level">
      <div>会员分类依据</div>
      <el-select
          clearable
          v-model="classification"
          style="width: 240px"
          placeholder="会员级别"
          @change="chooseClassification"
      >
        <el-option
            v-for="(item) in classificationList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
    </div>
    <div>
      <div v-if="classification==0&&isFlag==1">
        <div class="members_content" v-for="(item,index) in tableData" :key="index" >
          <div>{{ item.levelName }}</div>
          <div class="members_money">
            <div>
              <p style="width: 50px;align-self: center;margin-right: 10px">会费</p>
              <el-input clearable v-model="item.money" placeholder="请输入会费" @input="moneyYz()" maxlength="11" show-word-limit style="align-self: center"></el-input>
              <p style="width: 50px;align-self: center;margin-left: 10px">元</p>
            </div>
            <div>
              <p style="width: 50px;align-self: center;margin-right: 10px">会费</p>
              <el-input clearable v-model="item.days" placeholder="请输入天数" maxlength="11"  @input="dayYz()" show-word-limit style="align-self: center"></el-input>
              <p style="width: 50px;align-self: center;margin-left: 10px">天</p>
            </div>
          </div>
          <div class="button_wrap">
            <el-button
                class="save"
                type="primary"
                @click="submitForm(item)"
            >保存
            </el-button
            >
          </div>
        </div>
      </div>
      <div v-if="classification==1&&isFlag==1">
        <div class="members_content" v-for="(item,index) in sexList" :key="index">
          <div>{{ item.levelName }}</div>
          <div class="members_money">
            <div>
              <p style="width: 50px;align-self: center;margin-right: 10px">会费</p>
              <el-input clearable v-model="item.money" placeholder="请输入会费" @input="moneyYz1()" maxlength="11" show-word-limit style="align-self: center"></el-input>
              <p style="width: 50px;align-self: center;margin-left: 10px">元</p>
            </div>
            <div>
              <p style="width: 50px;align-self: center;margin-right: 10px">会费</p>
              <el-input clearable v-model="item.days" placeholder="请输入天数" maxlength="11"  @input="dayYz1()" show-word-limit style="align-self: center"></el-input>
              <p style="width: 50px;align-self: center;margin-left: 10px">天</p>
            </div>
          </div>
          <div class="button_wrap">
            <el-button
                class="save"
                type="primary"
                @click="submitForm(item)"
            >保存
            </el-button
            >
          </div>
        </div>
      </div>

      <div class="members_content" v-if="classification==1&&isFlag==2">
        <div>男</div>
        <div class="members_money">
          <div>
            <p style="width: 50px;align-self: center;margin-right: 10px">会费</p>
            <el-input clearable v-model="sex.manMoney" placeholder="请输入会费" @input="moneyYz2(1)" maxlength="11" show-word-limit style="align-self: center"></el-input>
            <p style="width: 50px;align-self: center;margin-left: 10px">元</p>
          </div>
          <div>
            <p style="width: 50px;align-self: center;margin-right: 10px">会费</p>
            <el-input clearable v-model="sex.mandays" placeholder="请输入天数" maxlength="11"  @input="dayYz2(1)" show-word-limit style="align-self: center"></el-input>
            <p style="width: 50px;align-self: center;margin-left: 10px">天</p>
          </div>
        </div>
        <div class="button_wrap">
          <el-button
              class="save"
              type="primary"
              @click="submitForm1"
          >保存
          </el-button
          >
        </div>
      </div>
      <div class="members_content" v-if="classification==1&&isFlag==2">
        <div>女</div>
        <div class="members_money">
          <div>
            <p style="width: 50px;align-self: center;margin-right: 10px">会费</p>
            <el-input clearable v-model="sex.wonMoney" placeholder="请输入会费" @input="moneyYz2(2)" maxlength="11" show-word-limit style="align-self: center"></el-input>
            <p style="width: 50px;align-self: center;margin-left: 10px">元</p>
          </div>
          <div>
            <p style="width: 50px;align-self: center;margin-right: 10px">会费</p>
            <el-input clearable v-model="sex.wondays" placeholder="请输入天数" maxlength="11"  @input="dayYz2(2)" show-word-limit style="align-self: center"></el-input>
            <p style="width: 50px;align-self: center;margin-left: 10px">天</p>
          </div>
        </div>
        <div class="button_wrap">
          <el-button
              class="save"
              type="primary"
              @click="submitForm2"
          >保存
          </el-button
          >
        </div>
      </div>
      <div class="members_content" v-if="classification==1&&isSexFlag==3">
        <div>男</div>
        <div class="members_money">
          <div>
            <p style="width: 50px;align-self: center;margin-right: 10px">会费</p>
            <el-input clearable v-model="sex.manMoney" placeholder="请输入会费" @input="moneyYz2(3)" maxlength="11" show-word-limit style="align-self: center"></el-input>
            <p style="width: 50px;align-self: center;margin-left: 10px">元</p>
          </div>
          <div>
            <p style="width: 50px;align-self: center;margin-right: 10px">会费</p>
            <el-input clearable v-model="sex.mandays" placeholder="请输入天数" maxlength="11"  @input="dayYz2(3)" show-word-limit style="align-self: center"></el-input>
            <p style="width: 50px;align-self: center;margin-left: 10px">天</p>
          </div>
        </div>
        <div class="button_wrap">
          <el-button
              class="save"
              type="primary"
              @click="submitForm1()"
          >保存
          </el-button
          >
        </div>
      </div>
      <div class="members_content" v-if="classification==1&&isSexFlag==2">
        <div>女</div>
        <div class="members_money">
          <div>
            <p style="width: 50px;align-self: center;margin-right: 10px">会费</p>
            <el-input clearable v-model="sex.wonMoney" placeholder="请输入会费" @input="moneyYz2(4)" maxlength="11" show-word-limit style="align-self: center"></el-input>
            <p style="width: 50px;align-self: center;margin-left: 10px">元</p>
          </div>
          <div>
            <p style="width: 50px;align-self: center;margin-right: 10px">会费</p>
            <el-input clearable v-model="sex.wondays" placeholder="请输入天数"  @input="dayYz2(4)" maxlength="11" show-word-limit style="align-self: center"></el-input>
            <p style="width: 50px;align-self: center;margin-left: 10px">天</p>
          </div>
        </div>
        <div class="button_wrap">
          <el-button
              class="save"
              type="primary"
              @click="submitForm2()"
          >保存
          </el-button
          >
        </div>
      </div>
    </div>
  </div>
</template> -->

<template>
  <div class="membersmoney_container" v-loading="loading">
    <div class="header_con d-flex">
      <div class="con_l">会员分类依据：<span>会员级别</span></div>
      <el-button type="primary" @click="edit">修改</el-button>
    </div>
    <div class="body_con d-flex">
      <div class="card green">
        <div class="card_header">
          <div class="title">会员级别</div>
          <div class="desc">根据申请入会会员申请不同职位设置、收取会费</div>
        </div>
        <div class="card_body">
          <div class="row d-flex"
            v-for="(item,index) in levelList"
            :key="index"
          >
            <div class="level d-flex">
              <img src="../../assets/images/member-icon.png" alt="">
              <div class="name">{{item.levelName}}</div>
            </div>
            <div class="val">
              会费 <span class="span">￥{{item.money ? item.money : '--'}}</span> ，
              会员有效期 <span class="span">{{item.days ? item.days : '--'}} <span>天</span> </span>
            </div>
          </div>
        </div>
      </div>
      <div class="card pink">
        <div class="card_header">
          <div class="title">性别</div>
          <div class="desc">根据申请入会会员性别不同设置、收取会费</div>
        </div>
        <div class="card_body">
          <div class="row d-flex"
            v-for="(item,index) in sexList"
            :key="index"
          >
            <div class="level d-flex">
              <img src="../../assets/images/member-icon.png" alt="">
              <div class="name">{{item.levelName}}</div>
            </div>
            <div class="val">
              会费 <span class="span">￥{{item.money ? item.money : '--'}}</span> ，
              会员有效期 <span class="span">{{item.days ? item.days : '--'}} <span>天</span> </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <membermoney-pop ref="membermoneyPop" @success="success" />
  </div>
</template>

<script>
import {upMemberFee, selectMemberLevelList, selectMemberFeeList,addMemberFee} from '@/api/organization.js';
import membermoneyPop from './components/membermoneypop';

export default {
  name: "membersMoney",
  components: {
    membermoneyPop,
  },
  data() {
    return {
      // inputMaxL:10,
      // sex:{
      //   manMoney:'',
      //   mandays:'',
      //   wonMoney:'',
      //   wondays:''
      // },
      // tableData: [],
      // sexList:[],
      // classification: '',
      // classificationList: [{
      //   value: '0',
      //   label: '会员级别'
      // }, {
      //   value: '1',
      //   label: '性别'
      // }],
      // isFlag:1,
      // isSexFlag:1
      levelList: [],
      sexList: [],
      loading: false,
    }
  },
  mounted() {
    this.queryPageMoney();
  },
  methods: {
    queryPageMoney() {
      this.loading = true;
      let data = {
        associationId: localStorage.getItem("associationId"),
      };
      selectMemberFeeList(data).then(res=>{
        if(res.code == 200){
          this.loading = false;
          if(res.data.length){
            res.data.forEach(v=>{
              v.money = (v.money/100).toFixed(2)
            })
            this.levelList = res.data.filter(v => v.type == 0);
            this.sexList = res.data.filter(v => v.type == 1);
          }
        }else{
          this.loading = false;
        }
      }).catch(c=>{
        this.loading = false;
      })
    },
    edit(){
      this.$refs.membermoneyPop.init();
    },
    success(){
      this.queryPageMoney();
    },


    // chooseClassification() {
    //   if (this.classification == 0) {
    //     this.queryPageMoney();
    //   }else{
    //     this.queryPageMoney1()
    //   }
    // },
    // async queryPageMoney() {
    //   let data = {
    //     associationId: localStorage.getItem("associationId"),
    //     type:0
    //   };
    //   try {
    //     const result = await selectMemberFeeList(data);
    //     this.isFlag = 1
    //     for (let i in result.data) {
    //       result.data[i].money =  (result.data[i].money/100).toFixed(2)
    //     }
    //     this.tableData = result.data;
    //   } catch (error) {

    //   }
    // },
    // async queryPageMoney1() {
    //   let data = {
    //     associationId: localStorage.getItem("associationId"),
    //     type:1
    //   };
    //   try {
    //     const result = await selectMemberFeeList(data);
    //     if (result.data) {
    //       this.sexList = result.data;
    //       if (this.sexList.length == 1) {
    //         for (let i in this.sexList) {
    //           if (this.sexList[i].levelName == '男') {
    //             this.isSexFlag = 2
    //             this.isFlag = 1
    //           }else {
    //             this.isSexFlag = 3
    //             this.isFlag = 1
    //           }
    //           for (let i in result.data) {
    //             this.sexList[i].money =  (this.sexList[i].money/100).toFixed(2)
    //           }
    //         }
    //       }else {
    //         this.isSexFlag = 1
    //       }
    //     }else {
    //       this.isFlag = 2
    //     }
    //   } catch (error) {

    //   }
    // },
    // submitForm(item) {
    //   if (item.money == undefined || item.days == undefined) {
    //     this.$message({
    //       message: '请完善信息!',
    //       type: "error",
    //     });
    //   } else {
    //     let ajax = {
    //       memberFeeId: item.memberFeeId,
    //       days: item.days,
    //       money: parseInt(item.money * 100),
    //     }
    //     upMemberFee(ajax)
    //         .then(res => {
    //           if (res.code == 200) {
    //             this.$message({
    //               type: 'success',
    //               message: "保存成功",
    //             })
    //             // this.queryPageMoney()
    //           }
    //         })
    //   }
    // },
    // submitForm1(item) {
    //   if (this.sex.manMoney == "" || this.sex.mandays == "") {
    //     this.$message({
    //       message: '请完善信息!',
    //       type: "error",
    //     });
    //   } else {
    //     let ajax = {
    //       associationId: localStorage.getItem("associationId"),
    //       type:1,
    //       levelName:'男',
    //       memberLevelId: 0,
    //       days: this.sex.mandays,
    //       money: parseInt(this.sex.manMoney * 100),
    //     }
    //     addMemberFee(ajax)
    //         .then(res => {
    //           if (res.code == 200) {
    //             this.$message({
    //               type: 'success',
    //               message: "保存成功",
    //             })
    //             // this.queryPageMoney1()
    //           }
    //         })
    //   }
    // },
    // submitForm2(item) {
    //   if (this.sex.wonMoney == "" || this.sex.wondays == "") {
    //     this.$message({
    //       message: '请完善信息!',
    //       type: "error",
    //     });
    //   } else {
    //     let ajax = {
    //       associationId: localStorage.getItem("associationId"),
    //       type:1,
    //       levelName:'女',
    //       memberLevelId: 0,
    //       days: this.sex.wondays,
    //       money: parseInt(this.sex.wonMoney * 100) ,
    //     }
    //     addMemberFee(ajax)
    //         .then(res => {
    //           if (res.code == 200) {
    //             this.$message({
    //               type: 'success',
    //               message: "保存成功",
    //             })
    //             // this.queryPageMoney1()
    //           }
    //         })
    //   }
    // },
    // moneyYz(){
    //   let _this = this
    //   let list = _this.tableData
    //   for (let i in list) {
    //     list[i].money = String(list[i].money).replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
    //   }
    // },
    // dayYz(){
    //   let _this = this
    //   let list = _this.tableData
    //   for (let i in list) {
    //     list[i].days = String(list[i].days).replace(/\D+/,'')
    //   }
    // },
    // moneyYz1(){
    //   let _this = this
    //   let list = _this.sexList
    //   for (let i in list) {
    //     list[i].money = String(list[i].money).replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
    //   }
    // },
    // dayYz1(){
    //   let _this = this
    //   let list = _this.sexList
    //   for (let i in list) {
    //     list[i].days = String(list[i].days).replace(/\D+/,'')
    //   }
    // },
    // moneyYz2(num){
    //   if (num == 1) {
    //     this.sex.manMoney = String(this.sex.manMoney).replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
    //   }else if (num == 2) {
    //     this.sex.wonMoney = String(this.sex.wonMoney).replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
    //   }else if (num == 3) {
    //     this.sex.manMoney = String(this.sex.manMoney).replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
    //   }else if (num == 4) {
    //     this.sex.wonMoney = String(this.sex.wonMoney).replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
    //   }
    // },
    // dayYz2(num){
    //   if (num == 1) {
    //     this.sex.mandays = String(this.sex.mandays).replace(/\D+/,'')
    //   }else if (num == 2) {
    //     this.sex.wondays = String(this.sex.wondays).replace(/\D+/,'')
    //   }else if (num == 3 ) {
    //     this.sex.mandays = String(this.sex.mandays).replace(/\D+/,'')
    //   }else if (num == 4 ) {
    //     this.sex.wondays = String(this.sex.wondays).replace(/\D+/,'')
    //   }
    // },


  }
}
</script>

<style scoped lang="scss">

.membersmoney_container{
  background-color: #fff;

  .header_con{
    width: 100%;
    height: 71px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EEEEEE;
    padding: 0 24px;

    .con_l{
      font-size: 18px;
      font-weight: bold;
      color: #222222;

      span{
        font-size: 20px;
        font-weight: bold;
        color: #51CBCD;
      }
    }
  }

  .body_con{
    width: 100%;
    padding: 35px 44px;
    flex-wrap: wrap;

    .card{
      width: 560px;
      border-radius: 8px;
      overflow: hidden;
      margin-right: 20px;

      .card_header{
        width: 100%;
        height: 68px;
        padding: 10px 0 0 16px;

        .title{
          font-size: 18px;
          font-weight: 800;
        }

        .desc{
          font-size: 12px;
          font-weight: 400;
          opacity: 0.8;
          margin-top: 2px;
        }
      }

      .card_body{
        width: 100%;
        padding: 22px 16px 26px 16px;

        .row{
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
          &:last-child{
            margin-bottom: 0;
          }

          .level{
            align-items: center;

            img{
              width: 16px;
              height: 14px;
            }

            .name{
              font-size: 14px;
              font-weight: 800;
              margin-left: 5px;
            }
          }

          .val{
            font-size: 14px;
            font-weight: 400;
            text-align: right;

            .span{
              font-size: 18px;

              span{
                font-size: 14px;
              }
            }
          }
        }
      }

      &.green{
        background: #F2FEFE;

        .card_header{
          background: linear-gradient(270deg, #F3FFFF, #CEF0F0);

          .title{
            color: #2C4D4D;
          }

          .desc{
            color: #2C4D4D;
          }
        }

        .card_body{
          .row{
  
            .level{
  
              .name{
                color: #487575;
              }
            }

            .val{
              color: #487575;

              .span{
                color: #FF371C;
              }
            }
          }
        }
      }

      &.pink{
        background: #FFEFF4;

        .card_header{
          background: linear-gradient(270deg, #FFEFF4, #F6D0DC);

          .title{
            color: #5A303E;
          }

          .desc{
            color: #5A303E;
          }
        }

        .card_body{
          .row{
  
            .level{
  
              .name{
                color: #895969;
              }
            }

            .val{
              color: #895969;

              .span{
                color: #FF371C;
              }
            }
          }
        }
      }
    }
  }
}

/**.corporateInformation-view {
  padding: 16px 24px;
  background: #FFFFFF;
}

.members_level {
  display: flex;
}

.members_level > div {
  align-self: center;
  width: 200px;
  text-align: right;
  margin-right: 100px;
}

.members_content {
  display: flex;
  margin-top: 20px;
}

.members_content > div:nth-child(1) {
  align-self: center;
  width: 200px;
  text-align: right;
  margin-right: 100px;
}

.members_money {
  display: flex;
  flex: 1;
}

.members_money > div {
  display: flex;
  align-self: center;
}

.button_wrap {
  margin-left: 30px;
}

.button_wrap .save {
  width: 100px;
}
**/
</style>
